import React,{useEffect,useState} from "react";
import { useParams } from 'react-router-dom';
import {SampleNextArrow,SamplePrevArrow} from '../../functions/SliderArrow/SliderArrow.js';
import ServerApi from "../../services/server-api-service.js";

import Loading from "../loading";
import { Helmet } from "react-helmet";
import StandAloneSlider from "../stand-alone-slider/stand-alone-slider.js";

import Slider from 'react-slick';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

import './listing-gallery-style.css';
import '../stand-alone-gallery/stand-alone-gallery-page-style.css';
import '../stand-alone-slider/stand-alone-slider-style.css';
import { Link } from "@mui/material";

export default  function ListingGallery(props){
    const {saveState,width,dictionary} = props;
    const serverApi = new ServerApi();

    const [data,setData] = useState(null);
    const [currentSlide,setCurrentSlide] = useState(0);
    const [loading, setLoading] = useState(false);

    const smartLuxeLogo = require('../../elements/images/logo_negative_center.png')

    const params = useParams()

    useEffect(()=>{
        serverApi.getListingInformation(params.id, 'v1.1/')
        .then((data)=>{ 
            setData(data);
            setLoading(true);
        })
        .catch((error)=>{
            console.log(`ERROR: fetch listing informationn ... -> Component: ListingGallery ERROR:${error}`);
            // props.history.replace({pathname:`/page-not-found`});
        })

        saveState({
            isVisibleFooter: false,
            isVisibleHeader: false,
        });

      

        return function cleanUp(){
            if(saveState){
                saveState({
                    isVisibleFooter: true,
                    isVisibleHeader: true,
                });
            }
        }
    },[])

    useEffect(()=>{
      
    })

     function renderCounter(){
        let {data} =  props;
        if(!data) return null;
        return (
            <div className="Counter">
                {currentSlide+1} of {data.Media.length}
            </div>
        )
    }


    function renderMedia() {
        let {height } = props;

        const speed= 500;
        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            beforeChange: (current, next) => {
                setTimeout(()=>{
                    setCurrentSlide(next);
                }, speed)
              },
              speed
        };

        if(!data) return null;

        let SliderItems = data.Media.map((item, index) => {
            if (currentSlide === index) {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" >
                            <img style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                        <div className="TopSliderItem" >
                            <img style={{ maxHeight: height - 105 }} src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                            <div className="Other"></div>
                        </div>
                    </div>
                )
            }
        })
        return (
            <div className="StandAloneSlider">
                <div className="StandAloneSliderTopKo">
                    {/* <Slider {...settingsSlider}
                        adaptiveHeight={true}
                        centerMode={false}
                        focusOnSelect={true}
                        initialSlide={currentSlide}
                        onInit={()=>{
                            setCurrentSlide(currentSlide);
                        }}
                    >
                        {SliderItems}
                    </Slider> */}

                       
                </div>
               
                {/* {renderCounter()} */}
            </div>
        )

    }

    const {height} = props;

    return (
        <div>
         

            {loading ? (
                
                <div className="StandAlonePage">
                       <Helmet>
                        <title> {data.UnparseAddress} | Smart Luxe</title>
                    </Helmet>
                    <div className="title">
                        <span>{data.UnparseAddress}</span>
                    </div>

                    <div className="gallery">
                        <StandAloneSlider
                            data={data}
                            listingGallery={true}
                            dictionary={dictionary}
                            width={width}
                            height={height}
                        ></StandAloneSlider>
                    </div>

                    <div className="presented">
                        Presented by
                        <img className="logo" src={smartLuxeLogo} alt='logo' />
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </div>
    )
}