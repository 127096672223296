import React from 'react';
// import Parser from 'html-react-parser';
import ServerApi from '../../services/server-api-service';
import ReactPlayer from 'react-player'
import FindMyDealBlock from '../find-my-deal-block/';
import OffersGrid from '../offers-grid';
import './home-page-style.css';
import Neighborhoods from '../neighborhoods';
//videos    
// import DestopVideo from '../../elements/videos/smart_luxe_intro.mp4';
// import MobileVideo from '../../elements/videos/elli_website_bg_mobile.mp4';
import Helmet from 'react-helmet'
import HOne from "../h-one";
import ProjectCardsGrid from '../project-cards-grid';
import InnerArticleGrid from '../inner-article-grid';
import SeoService from '../../services/seo-service';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ArrayShuffle from '../../functions/array-shuffle/array-shuffle.js'

// const LOWVIDEO = 'https://ngirealty.com/video/smartluxe/smlx_new_construction_480.mp4';
// const LOWVIDEOMOBILE = 'https://ngirealty.com/video/smlx_new_construction_mobile_480.mp4';

const HIGHVIDEO = 'https://smartluxe.s3.amazonaws.com/smlx_new_construction_1080.mp4';
const HIGHVIDEOMOBILE = 'https://smartluxe.s3.amazonaws.com/smlx_new_construction_mobile_480.mp4';


class HomePage extends React.Component {
    interval = null;
    serverApi = new ServerApi();
    seoService = new SeoService();
    constructor(props) {
        super(props);
        this.state = {
            debug: false,
            videoIsReady: false,
            wordCounter: 0,
            wordFlag: false,
            highQualityDownloaded: false,
            randomNumberForLastCardImage: Math.floor(Math.random() * (1 + 1))
        }
    }

    getProjectCards() {
        this.serverApi.getProjectsList('', null)
            .then((data) => {
                let shortRentalCards = [],nonShortRentalCards = [];

                data.data.map((item)=>{
                    if(item.short_rental) shortRentalCards.push(item)
                    else nonShortRentalCards.push(item)
                })
                this.setState({
                    cards: data.data.sort(() => Math.random() - 0.5),
                    shortRentalCards:shortRentalCards,
                    nonShortRentalCards:ArrayShuffle(nonShortRentalCards),
                })

            })
            .catch((error) => {
                console.log(`ERROR: fetch getProjectCards ... -> Component: HomePage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    getAllArticles() {
        this.serverApi.getAllArticles('', null)
            .then((data) => {
                let articles = data.data;
                articles.sort((article1, article2) => { return new Date(article2.date) - new Date(article1.date) })
                this.setState({
                    articles: articles
                })

            })
            .catch((error) => {
                console.log(`ERROR: fetch getAllArticles ... -> Component: HomePage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    componentWillMount() {
        if (this.props.saveState) this.props.saveState({ currentPage: 'HomePage' })
        if (this.state.debug) {
            console.log('componentWillMount ... -> Component: HomePage');
        }
        this.serverApi.getFeaturedDeals()
            .then((data) => {
                this.setState({
                    featuredDeals: data
                })
            })
            .catch((error) => {
                console.log(`ERROR: fetch getFeaturedDeals ... -> Component: ListingPag  ERROR:${error}`);
            })

        this.getProjectCards();
        this.getAllArticles();
    }

    renderSlogan() {
        let { wordCounter } = this.state;
        let { dictionary } = this.props;
        let part_1_words = dictionary.slogan_part_1.split(' ');
        let part_2_words = dictionary.slogan_part_2.split(' ');

        const pageTransition = {
            in: {
                y: 0
            },
            out: {
                y: 70
            },
            exit: {
                y: -100
            }
        }

        return (
            <div className="Slogan">
                <div className="SloganPart1">
                    {part_1_words.map((word, index) => {
                        return (
                            <AnimatePresence
                                key={word + index}>
                                {index < wordCounter && (<motion.span

                                    className="SloganWord"
                                    variants={pageTransition}
                                    exit="exit"
                                    animate="in"
                                    initial="out"
                                    transition={{ delay: 1, default: { duration: 0.5 } }}
                                >{word}&nbsp;</motion.span>)}
                            </AnimatePresence>
                        )
                    }
                    )}
                    <br />
                </div>
                <div className="SloganPart2">
                    {part_2_words.map((word, index) => {
                        return (
                            <AnimatePresence
                                key={word + index}>
                                {index + part_1_words.length < wordCounter && (<motion.span

                                    variants={pageTransition}
                                    exit="exit"
                                    animate="in"
                                    initial="out"
                                    transition={{ delay: 1, default: { duration: 0.5 } }}
                                >{word}&nbsp;</motion.span>)}
                            </AnimatePresence>
                        )
                    })}
                </div>


                {/*<div className="SloganSmall">*/}
                {/*    {parsedSloganPowered}*/}
                {/*    <svg className="SloganLine" xmlns="http://www.w3.org/2000/svg" width="300.203" height="2.65" viewBox="0 0 449.203 2.65">*/}
                {/*    <defs>*/}
                {/*        <linearGradient id="linear-gradient" y1="0.5" x2="1" y2="0.5" gradientUnits="objectBoundingBox">*/}
                {/*            <stop offset="0" stopColor="#a96903" />*/}
                {/*            <stop offset="0.258" stopColor="#aa6a02" />*/}
                {/*            <stop offset="0.352" stopColor="#b07102" />*/}
                {/*            <stop offset="0.418" stopColor="#b97d02" />*/}
                {/*            <stop offset="0.472" stopColor="#c78e01" />*/}
                {/*            <stop offset="0.518" stopColor="#daa401" />*/}
                {/*            <stop offset="0.557" stopColor="#f0be00" />*/}
                {/*            <stop offset="0.579" stopColor="#ffd100" />*/}
                {/*            <stop offset="1" stopColor="#a96903" />*/}
                {/*        </linearGradient>*/}
                {/*    </defs>*/}
                {/*    <g id="Сгруппировать_13" data-name="Сгруппировать 13" transform="translate(-2822.173 274.933)">*/}
                {/*        <rect id="Прямоугольник_18" data-name="Прямоугольник 18" width="449.203" height="2.65" transform="translate(2822.173 -274.933)" fill="url(#linear-gradient)" />*/}
                {/*    </g>*/}
                {/*</svg>*/}
                {/*<Link to='/find-my-deal'>{dictionary.learn_more}</Link>*/}
                {/*</div>*/}
            </div>
        )
    }



    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    getVideoPlayer() {
        let { highQualityDownloaded } = this.state;
        let { mobileVerison } = this.props;
        const imgPreload = require('../../elements/images/video_preview_new.png');
        // const imgPreload = require('../../elements/images/video_preview.jpg');
        // const imgPreloadMobile = require('../../elements/images/video_preview_mobile.jpg');
        const imgPreloadMobile = require('../../elements/images/video_preview_mobile_new1.jpg');

        let currentVideo = ''; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        let currentPreloadImg = imgPreload;

        // if(!highQualityDownloaded){
        //     currentVideo = LOWVIDEO; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        //     currentPreloadImg = imgPreload;
        //     if (mobileVerison) {
        //         currentVideo = LOWVIDEOMOBILE;
        //         currentPreloadImg = imgPreloadMobile;
        //     }
        // }else{
        //     currentVideo = HIGHVIDEO; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        //     currentPreloadImg = imgPreload;
        //     if (mobileVerison) {
        //         currentVideo = HIGHVIDEOMOBILE;
        //         currentPreloadImg = imgPreloadMobile;
        //     }
        // }

        currentVideo = HIGHVIDEO; //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        currentPreloadImg = imgPreload;
        if (mobileVerison) {
            currentVideo = HIGHVIDEOMOBILE;
            currentPreloadImg = imgPreloadMobile;
        }
        return { currentVideo, currentPreloadImg }
    }

    render() {

        const iconFile = require('../../elements/icons/file.png');
        const iconLight = require('../../elements/icons/light.png');
        const iconPerson = require('../../elements/icons/person_a.png');
        const imgPreload = require('../../elements/images/video_preview_new.png');
        // const imgPreload = require('../../elements/images/video_preview.jpg');
        // const imgPreloadMobile = require('../../elements/images/video_preview_mobile.jpg');
        const imgPreloadMobile = require('../../elements/images/video_preview_mobile_new.jpg');
        const imgPreloadEmpty = require('../../elements/images/video_preview_empty.png');
        const imgPreloadMobileEmpty = require('../../elements/images/video_preview_mobile_empty.png');
        let videoUrls = this.getVideoPlayer();
        let currentVideo = videoUrls.currentVideo //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        // let currentVideo = 'https://youtu.be/xW1ajeA91DA' //https://smartluxe.s3.amazonaws.com/smart_luxe_intro.mp4      https://youtu.be/xW1ajeA91DA
        let currentPreloadImg = videoUrls.currentPreloadImg;
        let { dictionary, mobileVerison, hoveredMarkerId } = this.props;
        let { cards, highQualityDownloaded, articles, randomNumberForLastCardImage,shortRentalCards,nonShortRentalCards } = this.state;
        let preloadVideoUrl = HIGHVIDEO;
        if (mobileVerison) {
            preloadVideoUrl = HIGHVIDEOMOBILE
        }



        let PreloadPlayer = document.getElementById('PreloadPlayer');
        const pageTransition = {
            in: {
                opacity: 1,
                transition: { duration: 0.5 }
            },
            out: {
                opacity: 0,
                transition: { duration: 0.5 }
            }
        }
        //slogan
        // let sloganArray = dictionary.slogan.split(' ');
        // let firstPartSlogan = sloganArray.map((word, index) => {
        //     if (index !== sloganArray.length - 1) {
        //         return (`${word} `);
        //     }
        // })
        // let secondpartSlogan = sloganArray[sloganArray.length - 1];
        //end slogan
        if (this.state.featuredDeals) {
            return (
                <>
                    <motion.div
                        variants={pageTransition}
                        exit="out"
                        animate="in"
                        initial="out"
                    >
                        {/* META TAGS */}
                        {
                            <Helmet>
                                <title>Smart Luxe - {dictionary.slogan}</title>
                                <meta name="description" content={dictionary.deal_finder_desc} />
                                {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/`,
                                    })
                                }
                                {/* <meta property="og:url" content="https://smartluxe.com/" />
                                <meta property="og:title" content="Smart Luxe - Luxury Condos - Smart Deals" />
                                <meta property="og:description" content="Harness SMART LUXE artificial intelligence (AI) powered algorithm to find South Florida best luxury condo deals." />
                                <meta property="og:image" content="https://bitbucket.org/globetech/smart_luxe_website/src/762f241a044ad3542167e58eafaa5ae3958a28f4/src/elements/images/video_preview.jpg" />
                                <meta property="og:image:alt" content="Main page" />
                                <meta property="og:image:type" content="image/jpeg" />
                                <meta property="og:image:width" content="1280" />
                                <meta property="og:image:height" content="360" />

                                <meta property="twitter:title" content="Smart Luxe - Luxury Condos - Smart Deals" />
                                <meta property="twitter:description" content="Harness SMART LUXE artificial intelligence (AI) powered algorithm to find South Florida best luxury condo deals." />
                                <meta property="twitter:image" content="https://smartluxe.com/static/media/video_preview.09a5932a.jpg" />
                                <meta property="twitter:image:alt" content="Main page" />
                                <meta name="twitter:card" content="summary_large_image" /> */}


                                {/* <script type="application/ld+json">{`
  { "@context" : "https://schema.org",
    "@type" : "Organization",
    "url" : "http://www.t-mobile.com",
    "contactPoint" : [
      {
        "@type" : "ContactPoint",
        "telephone" : "+1-305-760-4884",
        "contactType" : "customer service",
        "availableLanguage" : "English",
        "areaServed" : "US"
      } ,
      {
        "@type" : "ContactPoint",
        "telephone" : "+1-305-285-8518",
        "contactType" : "customer service",
        "availableLanguage" : "Português",
        "areaServed" : "PT"
      } ,
      {
        "@type" : "ContactPoint",
        "telephone" : "+1-305-770-6124",
        "contactType" : "customer service",
        "availableLanguage" : "Español",
        "areaServed" : "ES"
      }  
      
      ] }`}
                                </script> */}

                                <script type="application/ld+json">
                                    {`{
                                        "@context": "https://schema.org",
                                        "@type": "WebSite",
                                        "url": "http://smartluxe.com/",
                                        "potentialAction": {
                                            "@type": "SearchAction",
                                            "target": "http://smartluxe.com/search",
                                            "query": "required"
                                        }
                                        }`
                                    }
                                </script>
                            </Helmet>
                        }

                        {/* META TAGS */}

                        <div className="MainPage Page MenuSpace">
                            <div className="Video">
                                <div className="MainPageVideoDiv">
                                    {/* logo video */}

                                    {/* {this.renderSlogan()} */}

                                    {/* <div className="Slogan">
                                <div className="SloganPart1">
                                    {dictionary.slogan_part_1}
                                </div>
                                <div className="SloganPart2">
                                    {dictionary.slogan_part_2}
                                </div>
                               
    
                            </div> */}
                                    {/* <div>
                                <div className={this.state.videoIsReady ? "ImgPrealoadVideoDiv" : "ImgPrealoadVideoDiv"}>
                                    <img src={imgPreload} className={this.state.videoIsReady ? "ImgPrealoadVideo" : "ImgPrealoadVideo"} />
                                </div>
                            </div> */}
                                    <div className={"ImgPreloadVideoDiv"}>
                                        <div>



                                        </div>
                                    </div>
                                    <div className='player-wrapper PlayerDiv'>
                                        <div className="Location">
                                            MIAMI
                                        </div>
                                        <img  src={currentPreloadImg} alt='preload' className={this.state.videoIsReady ? "Hidden" : "ImgPreloadVideo"} />
                                        <div className={this.state.videoIsReady ? "PlayerBlock" : "Hidden"}>
                                            {/*                                     
                                    <ReactPlayer
                                            className={highQualityDownloaded==true?('react-player fixed-bottom MainPageVideo'):('react-player HIDDEN')}
                                            id ={'PreloadPlayer'}
                                            url={preloadVideoUrl}
                                            width='100%'
                                            height='100%'
                                            controls={false}
                                            playsinline={true}
                                            muted={true}
                                            loop={true}
                                            playing={true}
                                            onBufferEnd={()=>{
                                                this.setState({
                                                    highQualityDownloaded:true
                                                })
                                            }}
                                            onReady={()=>{
                                                this.setState({ videoIsReady: true })
                                            }}
                                            config={{
                                                youtube: {  
                                                    playerVars: {   
                                                        modestbranding: 1,
                                                        color: 'red'
                                                    }
                                                },

                                            }}
                                            ></ReactPlayer> */}

                                            <ReactPlayer
                                                className={'react-player fixed-bottom MainPageVideo'}
                                                url={currentVideo}
                                                width='100%'
                                                height='100%'
                                                controls={false}
                                                playsinline={true}
                                                muted={true}
                                                loop={true}
                                                playing={true}
                                                config={{
                                                    youtube: {
                                                        playerVars: {
                                                            modestbranding: 1,
                                                            color: 'red'
                                                        }
                                                    },

                                                }}
                                                onReady={() => {
                                                    // if (!this.state.videoIsReady) {
                                                    //     setInterval(async () => {
                                                    //         let { wordFlag, wordCounter } = this.state;
                                                    //         if (this.state.wordCounter === this.props.dictionary.slogan_part_1.split(' ').length && !wordFlag) {
                                                    //             await this.sleep(1000);
                                                    //         }
                                                    //         if (!wordFlag) {
                                                    //             this.setState((prevState) => {
                                                    //                 return {
                                                    //                     wordCounter: ++prevState.wordCounter
                                                    //                 }
                                                    //             })
                                                    //         } else if (wordFlag) {
                                                    //             this.setState((prevState) => {
                                                    //                 return {
                                                    //                     wordCounter: --prevState.wordCounter
                                                    //                 }
                                                    //             })
                                                    //         }
                                                    //         if (wordCounter >= 5) setTimeout(() => { this.setState({ wordFlag: true }) }, 2000)
                                                    //         else if (wordCounter === 0) setTimeout(() => { this.setState({ wordFlag: false }) }, 2000)
                                                    //     }, 500)
                                                    // }

                                                    // this.setState({ videoIsReady: true })
                                                    setTimeout(() => {
                                                        this.setState({ videoIsReady: true })
                                                    }, 1000)

                                                }}
                                            />
                                        </div>


                                    </div>

                                </div>
                            </div>

                            <div className="ProductBlock FeatureProducts">
                                <Link to={'/projects'}>
                                    <HOne
                                        color={'black'}
                                        title={dictionary.featured_projects}
                                    />
                                </Link>
                                <ProjectCardsGrid
                                    cards={nonShortRentalCards}
                                    limit={7}
                                    dictionary={dictionary}
                                    hoveredMarkerId={hoveredMarkerId}
                                    lastCard={true}
                                    randomNumberForLastCardImage={Boolean(randomNumberForLastCardImage)}
                                />
                            </div>

                            
                            {/* <div className="ProductBlock FeatureProducts">
                                    <HOne
                                        color={'black'}
                                        title={'SHORT-TERM Rental Projects'}
                                    />
                                <ProjectCardsGrid
                                    cards={shortRentalCards}
                                    limit={7}
                                    dictionary={dictionary}
                                    hoveredMarkerId={hoveredMarkerId}
                                    lastCard={true}
                                    randomNumberForLastCardImage={!Boolean(0)}
                                />
                            </div> */}

                            <div className="ProductBlock FeaturedSmartDeals">
                                <OffersGrid
                                    titleLink={true}
                                    title={dictionary.featured_deals}
                                    data={this.state.featuredDeals}
                                    width={this.props.width}
                                    dictionary={dictionary}
                                    randomNumberForLastCardImage={Boolean(randomNumberForLastCardImage)}
                                />
                            </div>


                            <div className="ProductBlock Neighborhoods">
                                <Neighborhoods
                                    dictionary={dictionary}
                                    data={this.props.neighboorhoods}
                                />
                            </div>


                            <div className="ProductBlock MoreArticles">
                                <Link to={'/articles'}>
                                    <HOne title={dictionary.recent_articles}
                                        color={'black'}
                                    />
                                </Link>
                                <InnerArticleGrid
                                    dictionary={dictionary}
                                    data={articles}
                                    limit={3}
                                />
                            </div>


                            {/* <FindMyDealBlock dictionary={dictionary} /> */}


                            {/* moved to about page */}

                            {/* <div>
                             
                                <div className="OurValueBlock">
                                    <HOne title={dictionary.our_values} />
                                    <br /><br /><br />
                                    <div className="ValuesBody">
                                        <div>
                                            <img className="Icon" src={iconFile} alt=""></img>
                                            <h2>{dictionary.value_1.toUpperCase()}</h2>
                                            <div className="Separator"></div>
                                            <span className="Text">
                                                {dictionary.value_1_desc}
                                            </span>
                                        </div>
                                        <div>
                                            <img className="Icon" src={iconLight} alt=""></img>
                                            <h2>{dictionary.value_2.toUpperCase()}</h2>
                                            <div className="Separator"></div>
                                            <span className="Text">
                                                {dictionary.value_2_desc}
                                            </span>
                                        </div>
                                        <div>
                                            <img className="Icon" src={iconPerson} alt=""></img>
                                            <h2>{dictionary.value_3.toUpperCase()}</h2>
                                            <div className="Separator"></div>
                                            <span className="Text">
                                                {dictionary.value_3_desc}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div> */}


                        </div>
                    </motion.div>
                </>
            );
        } else return null;

    }


}

export default HomePage;