import React from 'react';
import ServerApi from '../../services/server-api-service';
import FormCheckService from '../../services/form-check-service';
import StringProcessingService from '../../services/string-processing-service';
import {
    BrowserRouter as Router,
    Link,
    withRouter
} from "react-router-dom";

import './footer-style.css';
class Footer extends React.Component {
    stringProcessingService = new StringProcessingService();
    formCheckService = new FormCheckService();
    serverApi = new ServerApi();
    constructor(props) {
        super(props)
        this.state = {
            debug: false,
            contactForm: {
                userFullName: '',
                userPhone: '',
                userEmail: ''
            },
            contactIsSended: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
    }

    handleInput(event) {
        this.setState({
            contactForm: {
                ...this.state.contactForm,
                [event.target.name]: event.target.value
            }
        })
    }

    handleSubmit(event) {
        if (!this.formCheckService.checkContactForm(this.state.contactForm)) {
            this.serverApi.sendForm(this.state.contactForm)
                .then((data) => {
                    if (this.state.debug) {
                        console.log(data);
                    }
                    this.setState({
                        contactIsSended: true
                    })
                })
                .catch((error) => {
                    console.log(`ERROR: fetch sendForm ... -> Component: Footer ERROR:${error}`);
                })
        }
    }

    renderAnswer() {
        let { dictionary } = this.props;
        if (this.state.contactIsSended) {
            const okeyIcon = require('../../elements/icons/okey.png');
            return (
                <div className="FooterFormThank">
                    <img src={okeyIcon} alt='okey' className="FooterFormThankIcon" />
                    <div>
                        {dictionary.thank_you_short}
                    </div>
                </div>
            )
        }
    }

    render() {
        const logoImg = require('../../elements/images/logo_negative_left.png');
        const logoImgCenter = require('../../elements/images/logo_negative_center.png');
        const iconSmallLocation = require('../../elements/icons/s_location_footer.png');
        const iconSmallTelephone = require('../../elements/icons/s_phone_footer.png');
        const iconSmallLetter = require('../../elements/icons/s_letter_footer.png');
        const iconInstagram = require('../../elements/icons/instagram_icon.svg').default;
        const iconFB = require('../../elements/icons/fb_icon.svg').default;
        //const iconLock = require('../../elements/icons/s_secured.png');

        const realtor_icons = require('../../elements/images/realtor_icons.png');

        let { neighboorhoods, dictionary, currentProjectLogoInvert, hideElems } = this.props;
        let NeighborhoodsItems = () => {
            return (
                <>
                    <h3 className="FooterBlockTitle">{dictionary.neighborhoods}</h3>
                    <div id="NeighborhoodsItems">
                        {neighboorhoods.map((item, index) => {
                            return (<Link key={item.id} to={`/neighborhood/${item.id}/${this.stringProcessingService.StringFormatUrl(item.name)}`} className="Item" >{item.name}</Link>)
                        })}
                    </div>
                </>
            )

        }

        let ContactInfo = () => {
            if (!hideElems) {
                return (
                    <div className="FirstBlock FooterBlock" >
                        <Link to="/"><img className="FooterLogoImg" src={logoImg} alt=""></img></Link>
                        <div>
                            NG International Realty LLC (DBA SmartLuxe)
                        </div>
                        <div>
                            <img className="FooterIcon" src={iconSmallLocation} alt=""></img>
                            <a target="_blank" href={`https://www.google.com/maps/place/450+N+Park+Rd+%23503,+Hollywood,+FL+33021,+%D0%A1%D0%A8%D0%90/data=!4m2!3m1!1s0x88d9abc2ac1064ab:0x6ee3a982cc12a43a?sa=X&ved=2ahUKEwjf4JGV0anyAhXqtYsKHa0uABYQ8gF6BAgOEAE`} className="FooterContacts">
                                450 N. Park Road #503 Hollywood, FL 33021
                            </a>
                        </div>

                        <div>
                            <img className="FooterIcon" src={iconSmallTelephone} alt=""></img>
                            <a href="tel:+1305760488" className="FooterContacts">+1 305-760-4884</a>
                        </div>

                        <div className="LastColumn">
                            <div>
                                <img className="FooterIcon" src={iconSmallLetter} alt=""></img>
                                <a href="mailto:info@smartluxe.com" className="FooterContacts">info@smartluxe.com</a>
                            </div>

                            <div>
                                <img className="FooterIcon" src={iconFB} alt=""></img>
                                <a href="https://www.facebook.com/SmartLuxeRealty" className="FooterContacts">Facebook</a>
                            </div>


                            <div>
                                <img className="FooterIcon" src={iconInstagram} alt=""></img>
                                <a href="https://www.instagram.com/smart.luxe.realty/" className="FooterContacts">Instagram</a>
                            </div>

                        </div>
                    </div>
                )
            } else return null;
        }
        //let { handleNeighborhoods } = this.props;

        if (currentProjectLogoInvert !== null && currentProjectLogoInvert !== undefined) {
            return (
                <div className="Footer">
                    <div className="FooterProjectMain">

                        <div className={"ProjectLogoBlock"}>
                            <Link to="/"><img className="ProjectLogo" src={currentProjectLogoInvert} alt=""></img></Link>
                            <Link to="/"><img className="ProjectLogo" src={logoImgCenter} alt=""></img></Link>
                        </div>

                        <div className="FooterBlock">
                            <div className="FourthBlockText">
                                {dictionary.disclaimer}
                                <br />
                                <br />
                                {dictionary.copyright} {new Date().getFullYear()}.
                                <br />
                                <br />
                                <img className="RealtorIcons" src={realtor_icons} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="Footer">
                    <div className="FooterMain" style={hideElems? {alignItems:'center',justifyContent:'space-around',maxWidth:'1000px'} : null}>
                        {ContactInfo()}
                        {((document.location.pathname.includes('/selection') &&
                            document.location.pathname.includes('/listing')) || (document.location.pathname.includes('/selection') || hideElems)) ? (
                                null
                            ) : (
                                <div className="SecondBlock FooterBlock">
                                    <div className="FooterQuickLinks">
                                        <h3 className="FooterBlockTitle">{dictionary.quick_links}</h3>
                                        <div className="Links">
                                            <Link to="/projects">{dictionary.new_construction}</Link>
                                            <Link to="/articles">{dictionary.articles.toUpperCase()}</Link>
                                            <Link to="/find-my-deal">{dictionary.buy}</Link>
                                            <Link to="/sell">{dictionary.sell}</Link>

                                            <Link to="/about">{dictionary.about}</Link>
                                            {/* <Link to="/find-my-deal">{dictionary.find_my_deal}</Link> */}
                                            <Link to="/team">{dictionary.our_team}</Link>
                                            <Link to="/contact">{dictionary.contact}</Link>
                                            <Link to="/search">{dictionary.search}</Link>

                                            <Link to="/privacy">{dictionary.privacy_policy}</Link>
                                        </div>
                                    </div>
                                </div>
                            )}

                        {((document.location.pathname.includes('/selection') &&
                            document.location.pathname.includes('/listing')) || (document.location.pathname.includes('/selection') || hideElems)) ? (
                                null
                            ) : (
                                <div className="ThirdBlock FooterBlock">
                                    {NeighborhoodsItems()}
                                    {/* <div className="FooterContactUs">
                            <h3 className="FooterBlockTitle">{dictionary.contact_us}</h3>
                            <form>
                                <div>
                                    <input name='userFullName' value={this.state.contactForm.userFullName} onChange={this.handleInput} className="Input" type="text" placeholder={dictionary.full_name}></input>
                                    <input name='userPhone' value={this.state.contactForm.userPhone} onChange={this.handleInput} className="Input" type="text" placeholder={dictionary.phone}></input>
                                    <input name='userEmail' value={this.state.contactForm.userEmail} onChange={this.handleInput} className="Input" type="text" placeholder={dictionary.email}></input>
                                </div>
                                <div className="SecureDiv">
                                    <img className="SecureIcon" src={iconLock} alt="" />
                                    <span className="Text">
                                        {dictionary.information_secure}
                                    </span>
                                </div>
                                <div className="BottomFormPart">
                                    {this.renderAnswer()}
                                    <input className="FooterSubmit" onClick={this.handleSubmit} value={dictionary.send_contact_details} type="Button" />
                                </div>
                            </form>
                        </div> */}

                                </div>
                            )}

                        {hideElems && <Link to="/"><img className="FooterLogoImg" src={logoImg} alt=""></img></Link>}
                        <div className="FourthBlock FooterBlock" style={hideElems?{width:'50%'}:null}>
                            <div className="FourthBlockText" >
                                {dictionary.disclaimer}
                                <br />
                                <br />
                                {dictionary.copyright} {new Date().getFullYear()}.
                            <br />
                                <br />
                                <img className="RealtorIcons" src={realtor_icons} alt=""></img>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Footer;