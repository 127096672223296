import React from 'react';

function SoldIcon(props){

    let {width} = props;
    let imgSrc = require('../../elements/icons/sold-medium.png');
    // if(width<=1000) imgSrc = require('../../elements/icons/sold-large.png');
    return(<img src={imgSrc} alt='Sold Icon'/>);
}

export default SoldIcon;