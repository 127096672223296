import React from 'react';
import HOne from "../h-one";
import Helmet from 'react-helmet';
import './articles-page-style.css'
import ServerApi from '../../services/server-api-service';
import StringProcessingService from '../../services/string-processing-service';
import InnerArticleGrid from '../inner-article-grid';


class ArticlesPage extends React.Component {
    serverApi = new ServerApi();
    stringProcessingService = new StringProcessingService();
    constructor(props) {
        super(props);
        this.state = {
            currentPage:1,
            totalPages:0
        }
    }

    getArticles(page) {
        this.serverApi.getAllArticles(page)
            .then((data) => {
                this.setState({
                    articles: data.data,
                    totalPages:data.meta.last_page+1
                })

            })
            .catch((error) => {
                console.log(`ERROR: fetch getArticles ... -> Component: ArticlesPage ERROR:${error}`);
                // this.props.history.replace({pathname:`/page-not-found`});
            })
    }

    componentWillMount() {
        let params = this.stringProcessingService.GetParamsFromUrl();
        if (params.get('page') != null) {
            this.getArticles(params.get('page'));
            this.setState({
                currentPage:parseInt(params.get('page'))
            })
        }
        else this.getArticles(1);
    }

    switchToPage = (page) => {
        this.setState({
            currentPage: page,
        })
        window.history.pushState(null, 'Articles | SmartLuxe', `/articles?page=${page}`);
        this.getArticles(page)
    }


    renderPagination() {
        // let { dictionary } = this.props;
        let { totalPages, currentPage } = this.state;
        if (totalPages !== undefined) {
            const result = [];
            for (let i = 1; i < totalPages; i++) {
                if (totalPages > 10) {
                    if (currentPage < 3) {
                        if (i < 4) {
                            result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if(i===3){
                                result.push(<div key ={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else if (currentPage > totalPages - 3) {
                        if (i < 4) {
                            result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                            if(i===3){
                                result.push(<div key ={` PaginationDots ${i}`} className="PaginationDots">...</div>)
                            }
                        } else if (i > totalPages - 4) {
                            result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    } else {
                        if (i > currentPage - 4 && i < currentPage + 4) {
                            result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                        }
                    }

                } else {
                    result.push(<div key ={` PaginationItem ${i}`} className="PaginationItem" id={i === currentPage ? "PaginationItemCurrent" : ""} onClick={() => { this.switchToPage(i) }}>{i}</div>)
                }
            }
            return (<div className="PaginationDiv">{result}</div>)
        }
    }

    render() {
        let { articles } = this.state;
        let { width, dictionary } = this.props;
        let logo = require('../../elements/images/about_page.png');
        // if(articles!= null && Array.isArray(articles) ){
        //     for (const article of articles) {
        //         if(article.project!= null && article.project.logo!=null){
        //             logo = article.project.logo;
        //             break;
        //         }
        //     }
        // }
        return (
            <div className={"ArticlesPage"}>
                <Helmet>
                    {/* <title> {metaTitle} | Smart Luxe</title>
                        <meta name="description" content={description} />
                        {projectName != null && projectId != null ? <link rel="canonical" href={`/articles/${data.id}/${this.stringProcessingService.StringFormatUrl(metaTitle)}`}></link> : null} */}
                </Helmet>
                <div className="PageLogo">
                    {/* <img id="PageLogoImg" alt="Logo" src={`https://ngirealty.com${logo}`}></img> */}
                    <img id="PageLogoImg" alt="Logo" src={logo}></img>
                    <div id="PageLogoTitle">
                        <HOne line={width > 600 ? "lagre" : "none"} title={dictionary.articles} className={'Title'} />
                    </div>
                </div>

                <div className={"PageBody"}>
                <InnerArticleGrid
                        dictionary={dictionary}
                        data={articles}
                    />
                    
                </div>
                {this.renderPagination()}

            </div>
        )
    };

}

export default ArticlesPage;