import React from 'react'
import HOne from '../h-one';
import ServerApi from '../../services/server-api-service';
import ConfigurationService from '../../services/configuration-service';
import UserForm from '../user-form';
import ProjectCardsGrid from '../project-cards-grid';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SeoService from '../../services/seo-service';

import './short-rental-page-style.css';
const inititalState = {
    debug: true,
    contactInfo: {
        userComment: "",
        userEmail: "",
        userFullName: "",
        userPhone: "",
        project: ''
    },
}

class ShortRentalPage extends React.Component {
    serverApi = new ServerApi()
    configurationService = new ConfigurationService();
    seoService = new SeoService();
    constructor(props) {
        super(props)
        this.state = inititalState;
    }

    componentDidMount() {
        this.serverApi.getProjectsList('', null, { short_term: true })
            .then((res) => {
                this.setState({
                    data: res.data
                })
            })
    }

    renderShortTerm() {
        const termIcon = require(`../../elements/images/seal_short_term_${this.configurationService.getCurrentLanguagePrefix()}.png`);
        return (
            <div className="ShortTermIcon">
                <img src={termIcon} alt='Short Term Icon' />
            </div>
        )
    }

    render() {
        let { dictionary, hoveredMarkerId, width, country } = this.props;
        let { data, contactInfo } = this.state;

        const pageLogo = require('../../elements/images/our_team_page.jpg');


        return (
            <div className='ShortRentalPage MenuSpace'>
             <Helmet>
                    <title> {dictionary.contact_us} | Smart Luxe</title>
                    <meta name="description" content={dictionary.message_us} />
                    {this.seoService.insertHrefLangTags(
                                    {
                                        path: `/contact`,
                                       
                                    })
                                }
                </Helmet>

                <div className='PageLogo'>
                    <img id="PageLogoImg" alt="Logo" src={pageLogo}></img>
                    <div id="PageLogoTitle">
                        <HOne line={"lagre"} addclass={"lagresize"} title={"ShortTermArea"} />
                    </div>
                    {this.renderShortTerm()}
                </div>

                <div className='Description Block'>
                    <div className="LeftBlock">
                        <HOne title={'Short Rental Page'} align="center" />

                        <div className={"NeighborhoodDescription"}>
                            {'description'}
                        </div>
                    </div>
                </div>

                <div className='FeaturedProjects'>
                    <div className="FeatureProducts NeighborhoodLine">
                        <Link to={'/projects'}>
                            <HOne
                                mainClass="NeighborhoodLineTitle"
                                align={'center'}
                                color={'black'}
                                title={dictionary.featured_projects}
                            />
                        </Link>
                        <ProjectCardsGrid
                            shortTerm={false}
                            cards={data}
                            limit={100}
                            dictionary={dictionary}
                            hoveredMarkerId={hoveredMarkerId}
                            lastCard={false}
                        />
                    </div>

                </div>

                <div className='Form'>

                    <HOne line={''} title={`IMMEDIATE CASHFLOW OPPOTUNITIES`} />
                    <UserForm
                        clearForm={this.clearForm}
                        topPartRow={true}
                        contactForm={contactInfo}
                        handleInput={this.handleInput}
                        width={width}
                        message={dictionary.thank_you_deal}
                        country={country}
                        thankModal={true}
                        secureInfo={true}
                        buttonTitle={`request private consultation`}
                        dictionary={dictionary}
                        CustomInputStyle={{ padding: '0 0 0 5px' }}
                        style={{ textAlign: 'center', display: 'block', justifyContent: 'center' }} />
                </div>
            </div>
        );
    }
}

export default ShortRentalPage;