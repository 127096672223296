import React from 'react';
import Slider from 'react-slick';
import ListingPageModal from '../listing-page-modal';
import SoldIcon from '../sold-icon';
import Modal from '../modal';

import '../../lib/slick-1.8.1/slick.css';
import '../../lib/slick-1.8.1/slick-theme.css';

import './listing-slider-style.css'

import { SampleNextArrow, SamplePrevArrow } from '../../functions/SliderArrow/SliderArrow.js';
const arrow_play = require('./../../elements/icons/vr_play.png')
class ListingSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            currentSlide: 0,
            currentImgId: 0,
            isVisibleCaruselModal: false,
            isVisibleVurtual: false,
            matterPortUrl:null
        };
    }

    renderCounter() {
        let { currentImgId } = this.state;
        let { data } = this.props;

        return (
            <div className="Counter">
                {currentImgId + 1} of {data.Media.length}
            </div>
        )
    }

    openCaruselModal = () => {
        if (this.props.selection) {
            document.dispatchEvent(new CustomEvent('openModal', { detail: { name: 'selectionListingModal', value: true, currentSlide: this.state.currentSlide } }))
        }
        else
            if (this.props.width > 800) {
                this.setState({
                    isVisibleCaruselModal: true
                })
                if (this.props.setSliderModal) this.props.setSliderModal(true)
            }

    }

    openVirtualModal = () => {
        this.setState({
            isVisibleVurtual: true
        })

    }

    closeModal = () => {
        this.setState({
            isVisibleCaruselModal: false,
            isVisibleVurtual:false
        })
        if (this.props.setSliderModal) this.props.setSliderModal(false)
    }

    handleSwitchMedia(newImage) {
        this.setState({
            currentImgId: newImage.Order,
            currentSlide: newImage.Order,
        })
    }

    componentDidMount() {
        let { data } = this.props;
        if (data.VirtualTour != null &&data.VirtualTour.includes('matterport')) {
            if((window.navigator.userAgent.includes(' VR ')|| window.navigator.userAgent.includes(' Oculus'))){
                data.Media.unshift({
                    Order: data.Media.length,
                    Type: 'virtual',
                    MediaUrl: data.Media[data.Media.length-1].MediaUrl
                });
            }else{
                data.Media.push({
                    Order: data.Media.length,
                    Type: 'virtual',
                    MediaUrl: data.Media[0].MediaUrl
                });
            }

            this.setState((prevState)=>{
                if(prevState.matterPortUrl== null){
                    return{
                        matterPortUrl:data.VirtualTour
                    }
                }
            })
            
        }

        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    componentWillUnmount() {
        if(window.navigator.userAgent.includes(' VR ')|| window.navigator.userAgent.includes(' Oculus')){
            if (this.props.data.VirtualTour != null) this.props.data.Media.pop()
        }else{
            if (this.props.data.VirtualTour != null) this.props.data.Media.slice(1)
        }
      
    }

    renderModal() {
        let { data, analyseInfo, dictionary, width, height, dealScore = true, userForm, selection = false, country } = this.props;
        return (

            <ListingPageModal
                isVisible={this.state.isVisibleCaruselModal}
                data={data}
                currentSlide={this.state.currentSlide}
                analyseInfo={analyseInfo}
                dictionary={dictionary}
                closeModal={this.closeModal}
                width={width}
                height={height}
                country={country}
                userForm={userForm}
                dealScore={dealScore}
                selection={selection}
            />
        )
    }

    render() {

        let { data, width, selection = false,modals =true } = this.props;
        let {isVisibleVurtual,matterPortUrl } = this.state;

        if (this.props.data === undefined) {
            return null;
        }



        let SliderItems = this.props.data.Media.map((item, index) => {

            return (
                <div key={index}>
                    <div className="TopSliderItem" onClick={item.Type != null ? this.openVirtualModal : this.openCaruselModal}>
                        <img src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
                        {item.Type != null&& <img className='VirtualPlay' src={arrow_play} alt='Play'/>}
                    </div>
                </div>
            )

            // if (this.state.currentImgId === index) {
            //     return (
            //         <div key={index}>
            //             <div className="TopSliderItem" onClick={this.openCaruselModal}>
            //                 <img src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
            //             </div>
            //         </div>
            //     )
            // } else {
            //     return (
            //         <div key={index}>
            //             <div className="TopSliderItem" onClick={this.openCaruselModal}>
            //                 <img src={item.MediaUrl} className="TopSliderImg" alt="" ></img>
            //                 <div className="Other"></div>
            //             </div>
            //         </div>
            //     )
            // }
        })

        let SliderItemsBottom = this.props.data.Media.map((item, index) => {
            if (this.state.currentImgId === index) {
                return (
                    <div key={index}>
                        <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                            <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div key={index}>
                        <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, item)}>
                            <img src={item.MediaUrl} className="MediaImgSmall" alt="" ></img>
                            <div className="Other"></div>
                        </div>
                    </div>
                )
            }
        })


        // if (data.ListingId =='A11179537') {
        //     SliderItems.push(
        //         <div key={data.Media.length+1}>
        //             <div className="TopSliderItem" onClick={this.op}>
        //                 <img src={`https://media.cnn.com/api/v1/images/stellar/prod/oculus-quest-2-comparison-card.jpg?c=16x9&q=h_270,w_480,c_fill`} className="TopSliderImg" alt="" ></img>
        //                 <div className="Other"></div>
        //             </div>
        //         </div>
        //     )

        //     SliderItemsBottom.push(
        //         <div key={data.Media.length+1}>
        //         <div className="MediaSwitchItem" onClick={this.handleSwitchMedia.bind(this, {Order:data.Media.length+1})}>
        //             <img src={`https://media.cnn.com/api/v1/images/stellar/prod/oculus-quest-2-comparison-card.jpg?c=16x9&q=h_270,w_480,c_fill`} className="MediaImgSmall" alt="" ></img>
        //             <div className="Other"></div>
        //         </div>
        //     </div>
        //     )
        // }

        const settingsSlider = {
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
        };
        let minSlidersValue = 5;
        if (SliderItemsBottom.length < minSlidersValue) {
            minSlidersValue = SliderItemsBottom.length
        }
        const settingsNavSlider = {
            slidesToShow: minSlidersValue,//5

            responsive: [
                {
                    breakpoint: 1350,
                    settings: {
                        slidesToShow: minSlidersValue - 1, //4
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: minSlidersValue, //5
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 0,//0
                        slidesToScroll: 0
                    }
                }
            ],
        };

        return (
            <>
                <div>
                    <div className="SliderTop SliderTopKo">
                        {data.ClosePrice != null && data.ClosePrice > 0 && selection ? (
                            <div className='SliderSoldIcon'>
                                <SoldIcon width={width} />
                            </div>) : (null)}
                        <Slider {...settingsSlider}
                            asNavFor={this.state.nav2}
                            ref={slider => (this.slider1 = slider)}
                            // adaptiveHeight={true}
                            centerMode={false}
                            focusOnSelect={true}
                            afterChange={(index) => {
                                this.setState({
                                    currentSlide: index,
                                    currentImgId: index
                                })
                            }}

                        >
                            {SliderItems}
                        </Slider>
                        {this.renderCounter()}
                    </div>
                    <div className="SliderNavi">
                        <Slider  {...settingsNavSlider}
                            asNavFor={this.state.nav1}
                            ref={slider => (this.slider2 = slider)}
                            swipeToSlide={true}
                            arrows={false}
                            centerMode={true}
                            focusOnSelect={true}
                        >
                            {SliderItemsBottom}
                        </Slider>
                    </div>
                </div>
               {modals?(<>
                 {this.renderModal()}
                 <Modal
                     ModalBlurStyle={{ backgroundColor: "rgba(0,0,0,0.85)" }}
                     closeModal={this.closeModal.bind(this, false)}
                     modalClass={'MessageModal'}
                     closeColor={'#000'}
                     speed={0.5}
                     ModalStyle={{height:'90%',width:'90%', justifyContent: 'center', flexDirection: 'column', padding: "20px", alignItems: 'center' }}
                     isVisible={isVisibleVurtual}>
                     <iframe className='VirtualBlock' src={matterPortUrl}>
 
                     </iframe>
                 </Modal>
                 </>
               ):(
                null
               )}
            </>
        );
    }
}

export default ListingSlider;